<ng-container *transloco="let t; read: 'hestiaPages.home'">
  <ion-header [translucent]="true">
    <ion-toolbar color="light">
      <ion-title>{{ t('title') }}</ion-title>
      <ion-buttons slot="end">
        <ion-menu-button color="primary"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [fullscreen]="true" color="light" *ngIf="userState$ | async as user">
    <ion-grid fixed *ngIf="carePlans$ | async as carePlans">
      <ion-row *ngIf="!pageData?.autoHandleCreateCarePlan">
        <ion-col>
          <ion-text color="dark" class="ion-margin">
            <h2 [innerHTML]="t(!carePlans.length ? 'noCarePlan' : 'carePlanFound')"></h2>
          </ion-text>

          <ng-container *ngIf="pageData?.allowCreation">
            <ion-text>
              <p [innerHTML]="t(!carePlans.length ? 'noCarePlanDescription' : 'carePlanFoundDescription')"></p>
            </ion-text>

            <ion-button class="ion-margin" *ngIf="!carePlans.length" (click)="handleCreateCarePlan()">
              {{ t('create') }}
              <ion-icon name="add-circle-outline" slot="end" />
            </ion-button>
          </ng-container>

          <ion-button
            class="ion-margin"
            color="success"
            *ngIf="carePlans.length"
            (click)="pagesService.startCarePlan(carePlans[0].id!)"
          >
            {{ t('start') }}
            <ion-icon name="arrow-forward-outline" slot="end" />
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="pageData?.autoHandleCreateCarePlan">
        <ion-col>
          <ion-text *ngIf="!carePlans.length" color="dark" class="ion-margin">
            <ion-spinner name="dots"></ion-spinner>
          </ion-text>
          <ion-button
            class="ion-margin"
            color="success"
            *ngIf="carePlans.length"
            (click)="pagesService.startCarePlan(carePlans[0].id!)"
          >
            {{ t('start') }}
            <ion-icon name="arrow-forward-outline" slot="end" />
          </ion-button>
        </ion-col>
      </ion-row>
      <!-- 
      <ion-row>
        <ion-col [size]="6" *ngFor="let carePlan of carePlans">
          <ion-card button (click)="pagesService.startCarePlan(carePlan.id!)">
            <ion-card-header>
              <ion-card-title>{{ toDate(carePlan?.created) | dfnsFormat: 'PPp' }}</ion-card-title>
              <ion-card-subtitle>Tryk for at besvare spørgeskema</ion-card-subtitle>
            </ion-card-header>
            <ion-progress-bar [value]="getActivityStatus(carePlan.activity)" />
          </ion-card>
        </ion-col>
      </ion-row> -->
    </ion-grid>
  </ion-content>
</ng-container>
